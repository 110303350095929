import React, { useEffect, useMemo, useState } from 'react';
import './screeningQuestions.css';
import { Checkbox, TextField } from '@material-ui/core';

const pageConfig = {
  header: 'Potential Employee Screening Preferences',
  subHeader:
    'We can ask upto five knock-out questions (Geo-qualifications, legal work status, licensure) to disqualify or eliminate employee candidates that are not suitable for your agency. Choose upto five of the following',
};

export default function EmployeeScreeningPreferences(props) {
  const { apiFormData, handleChange, isMinOneRequired } = props;

  const [fieldsData, setFieldsData] = useState({
    legallyWorkInUS: '',
    workCities: '',
    certifications: '',
    validDriverLicenseAndtransportation: '',
    ownQualification: '',
  });

  const [checkboxData, setCheckboxData] = useState({
    legallyWorkInUS: '',
    workCities: '',
    certifications: '',
    validDriverLicenseAndtransportation: '',
    ownQualification: '',
  });

  useEffect(() => {
    if (apiFormData) {
      setDataOnInit(apiFormData);
    }
  }, [props]);

  const handleInputChange = (key, event) => {
    const data = {
      ...fieldsData,
      [key]: event.target.value,
    };
    setFieldsData(data);
    const formErr = checkFormError(checkboxData, data);
    handleChange(key, event.target.value, formErr);
  };

  const handelCheckboxChange = (key, value) => {
    if (value === true) {
      const dataToSend = {
        ...checkboxData,
        [key]: value,
      };
      setCheckboxData(dataToSend);
      if (
        key === 'legallyWorkInUS' ||
        key === 'validDriverLicenseAndtransportation'
      ) {
        const formErr = checkFormError(dataToSend, fieldsData);
        handleChange(key, true, formErr);
      } else {
        const formErr = checkFormError(dataToSend, fieldsData);
        handleChange(key, '', formErr);
      }
    } else {
      const data = {
        ...checkboxData,
        [key]: value,
      };
      setCheckboxData(data);
      const formErr = checkFormError(data, fieldsData);
      handleChange(key, null, formErr);
    }
  };

  const checkFormError = (checkboxFormData, fieldFormData) => {
    let fieldError = false;
    const fieldName = ['workCities', 'certifications', 'ownQualification'];
    fieldName.forEach((key) => {
      if (checkboxFormData[key] === true && !fieldFormData[key]) {
        fieldError = true;
      }
    });
    return fieldError;
  };

  const setDataOnInit = (data) => {
    const checkboxKeys = Object.keys(checkboxData);
    const dataKeys = Object.keys(data);
    const finalObj = {};
    dataKeys.forEach((keyVal) => {
      if (checkboxKeys.includes(keyVal) && data[keyVal]) {
        finalObj[keyVal] = true;
      }
    });
    setCheckboxData({ ...checkboxData, ...finalObj });
    setFieldsData({ ...fieldsData, ...data });
  };

  return useMemo(
    () => (
      <>
        <div className="screening-parent-container">
          <h2 className="screening-header">{pageConfig?.header}</h2>
          <h2 className="screening-sub-header">{pageConfig?.subHeader}</h2>
          {isMinOneRequired && (
            <h2 className="mandatory-note">Select atleast one option.</h2>
          )}
          <div className="screening-content-container">
            <div className="question-container">
              <div className="que-checkbox-container">
                <Checkbox
                  checked={checkboxData.legallyWorkInUS}
                  onChange={(event) => {
                    handelCheckboxChange(
                      'legallyWorkInUS',
                      event?.target?.checked,
                    );
                  }}
                />
                <h3>Can You legally work in the US?</h3>
              </div>

              <div className="que-checkbox-container">
                <Checkbox
                  checked={checkboxData.workCities}
                  onChange={(event) => {
                    handelCheckboxChange('workCities', event?.target?.checked);
                  }}
                />
                <h3>
                  Are you available to work in [enter to the right] and
                  surrounding areas?
                </h3>
                {checkboxData.workCities && (
                  <div style={{ paddingLeft: '20px' }}>
                    <TextField
                      style={{ width: '200px' }}
                      size="small"
                      error={
                        checkboxData.workCities === true &&
                        !fieldsData.workCities
                      }
                      value={fieldsData.workCities}
                      onChange={(val) => {
                        handleInputChange('workCities', val);
                      }}
                      label="Enter cities/counties/region"
                      variant="outlined"
                    />
                  </div>
                )}
              </div>

              <div className="que-checkbox-container">
                <Checkbox
                  checked={checkboxData.certifications}
                  onChange={(event) => {
                    handelCheckboxChange(
                      'certifications',
                      event?.target?.checked,
                    );
                  }}
                />
                <h3>Do you have a valid (i.e. HHA,CNA,…) certificate?</h3>
                {checkboxData.certifications && (
                  <div style={{ paddingLeft: '20px' }}>
                    <TextField
                      size="small"
                      error={
                        checkboxData.certifications === true &&
                        !fieldsData.certifications
                      }
                      value={fieldsData.certifications}
                      onChange={(val) => {
                        handleInputChange('certifications', val);
                      }}
                      label="Add Certificates"
                      variant="outlined"
                    />
                  </div>
                )}
              </div>

              <div className="que-checkbox-container">
                <Checkbox
                  checked={checkboxData.validDriverLicenseAndtransportation}
                  onChange={(event) => {
                    handelCheckboxChange(
                      'validDriverLicenseAndtransportation',
                      event?.target?.checked,
                    );
                  }}
                />
                <h3>
                  Do you have a valid drivers license and reliable means of
                  transportation?
                </h3>
              </div>

              <div className="que-checkbox-container">
                <Checkbox
                  checked={checkboxData.ownQualification}
                  onChange={(event) => {
                    handelCheckboxChange(
                      'ownQualification',
                      event?.target?.checked,
                    );
                  }}
                />
                <h3>Add my own qualification</h3>
                {checkboxData.ownQualification && (
                  <div style={{ paddingLeft: '20px' }}>
                    <TextField
                      size="small"
                      error={
                        checkboxData.ownQualification === true &&
                        !fieldsData.ownQualification
                      }
                      value={fieldsData?.ownQualification}
                      onChange={(val) => {
                        handleInputChange('ownQualification', val);
                      }}
                      label="Add Qualification"
                      variant="outlined"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    ),
    [fieldsData, checkboxData],
  );
}
