export const TABLE_HEADER = [
  {
    label: 'agency name',
    key: 'name',
    search: true,
    sort: true,
  },
  {
    label: 'agency access',
    key: 'status',
    search: false,
    sort: true,
    width: '40px',
  },
];
