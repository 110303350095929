const FormFields = [
  {
    CardHeading: 'General Settings',
    id: 'update_basic_agency_form',
    title: '',
    type: 'UPDATE_BASIC_AGENCY_FORM',
    list: {
      contact_name: {
        name: 'contact_name',
        value: '',
        type: 'text',
        id: 'contact_name-id',
        label: 'Contact Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 20,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      contact_phone: {
        name: 'contact_phone',
        value: '',
        type: 'phone',
        id: 'contact_number-id',
        label: 'Contact Number',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '(XXX) XXX-XXXX',
        error: false,
        required: true,
        width: 20,
        regExpValue: '^\\([2-9]\\d\\d\\) [2-9]\\d\\d-\\d{4}$',
      },
      contact_email: {
        name: 'contact_email',
        value: '',
        type: 'email',
        id: 'contact_email-id',
        label: 'Contact Email',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 20,
        regExpValue: '\\S+@\\S+\\.\\S+',
      },
      name: {
        name: 'name',
        value: '',
        type: 'text',
        id: 'agency-name-id',
        label: 'Agency Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 20,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      agency_website_url: {
        name: 'agency_website_url',
        value: '',
        type: 'text',
        id: 'agency-website-id',
        label: 'Agency Website',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 20,
      },
      country: {
        name: 'country',
        value: '',
        type: 'select',
        id: 'agency-country-id',
        label: 'Agency Country',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 15,
        values: [
          {
            label: 'USA',
            value: 'USA',
          },
          {
            label: 'Canada',
            value: 'CANADA',
          },
        ],
      },
      address: {
        name: 'address',
        value: '',
        type: 'text',
        id: 'agency-address-id',
        label: 'Agency Address',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 30,
        regExpValue: '\\b[a-zA-Z0-9]{1,50}\\b',
      },
      city: {
        name: 'city',
        value: '',
        type: 'text',
        id: 'agency-city-id',
        label: 'Agency City',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 20,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      state: {
        name: 'state',
        value: '',
        type: 'select',
        id: 'agency-state-id',
        label: 'Agency State',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 20,
        values: [],
      },
      zip_postal_code: {
        name: 'zip_postal_code',
        value: '',
        type: 'text',
        id: 'agency-zip-id',
        label: 'Agency Zip Code',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 20,
        regExpValue: '\\b[0-9]{4,8}\\b',
      },
      agency_type: {
        name: 'agency_type',
        value: [],
        type: 'select',
        id: 'agency-type-id',
        label: 'Agency Type',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 30,
        values: [],
      },
      service_provided: {
        name: 'service_provided',
        value: '',
        type: 'multiSelect',
        id: 'service-provided-id',
        label: 'Service Provided',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 30,
        values: [],
        required: true,
      },
      description: {
        name: 'description',
        value: '',
        type: 'text',
        id: 'agency-description-id',
        label: 'Agency Description',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 40,
        regExpValue: '^.{1,500}$',
      },
      min_hours_per_visit: {
        name: 'min_hours_per_visit',
        value: '',
        type: 'number',
        id: 'min_hours_per_visit',
        label: 'Minimum Hours of Care Required Per Visit',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 25,
        startAdornment: '',
        regExpValue: '^\\d{1,2}$',
      },
      min_hours_per_day: {
        name: 'min_hours_per_day',
        value: '',
        type: 'number',
        id: 'min_hours_per_day',
        label: 'Minimum Hours of Care Required Per Day',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 25,
        startAdornment: '',
        regExpValue: '^\\d{1,2}$',
      },
      min_hours_per_week: {
        name: 'min_hours_per_week',
        value: '',
        type: 'number',
        id: 'min_hours_per_week',
        label: 'Minimum Hours of Care Required Per Week',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 25,
        startAdornment: '',
        boxProps: {
          paddingTop: 3.75,
        },
        regExpValue: '^\\d{1,2}$',
      },
      pricing_hour_detail: {
        name: 'pricing_hour_detail',
        value: '',
        type: 'text',
        id: 'pricing_hour_detail',
        label:
          'Pricing and Hours Details and Terms (Enter any details about minimum hours/days and any rate information you want to share with visitors.)',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 70,
        regExpValue: '^.{1,500}$',
      },
      payment_option: {
        name: 'payment_option',
        value: [],
        type: 'multiSelect',
        id: 'payment_option',
        label: 'Payment Options',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 30,
        values: [],
      },
      office_hours_timezone: {
        name: 'office_hours_timezone',
        value: '',
        type: 'select',
        id: 'time-zone-id',
        label: 'Time Zone',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 28,
        values: [],
      },
      office_hours_from: {
        name: 'office_hours_from',
        value: '09:00',
        type: 'time',
        id: 'from-time-id',
        label: 'Office Hours From Time',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 28,
        required: true,
      },
      office_hours_to: {
        name: 'office_hours_to',
        value: '17:00',
        type: 'time',
        id: 'to-time-id',
        label: 'Office Hours To Time',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 28,
        required: true,
      },
      '24hour': {
        name: '24hour',
        type: 'singleCheckbox',
        id: '24hour-id',
        label: 'Open 24 Hours',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 16,
        value: false,
        labelPlacement: 'end',
      },
      coverage_area: {
        name: 'coverage_area',
        value: '',
        type: 'text',
        id: 'coverage-area-id',
        label: 'Coverage Cities',
        margin: 'normal',
        fullWidth: true,
        multiline: true,
        placeholder: '',
        error: false,
        required: true,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,50}\\b',
      },
      coverage_area_cities: {
        name: 'coverage_area_cities',
        value: '',
        type: 'text',
        id: 'coverage-zip-id',
        label: 'Coverage Zip Codes',
        margin: 'normal',
        fullWidth: true,
        multiline: true,
        placeholder: '',
        error: false,
        required: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,50}\\b',
      },
      uncovered_agency_uuid: {
        name: 'uncovered_agency_uuid',
        value: '',
        type: 'text',
        id: 'uncovered-agency-UUID-id',
        label: 'Uncovered Agency UUID',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 25,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
        required: false,
        showToUserType: ['BOOMERS_ADMIN'],
      },
    },
  },
  {
    CardHeading: 'Notification Settings',
    id: 'update_basic_agency_form',
    title: '',
    type: 'UPDATE_BASIC_AGENCY_FORM',
    list: {
      client_lead_notifications_emails: {
        name: 'client_lead_notifications_emails',
        value: '',
        maxEmail: 20,
        type: 'multiEmail',
        id: 'multi-email-id-1',
        label:
          'Send Client Lead Notification To: (Max 20 emails with comma separated values)',
        group: 'notification',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
      },
      client_lead_notification_phones: {
        name: 'client_lead_notification_phones',
        value: '',
        maxMobileNumbers: 10,
        type: 'multiPhone',
        id: 'multi-phone-id-1',
        label:
          'Send Client Lead Notification SMS/Text To: (Max 10 numbers with comma separated values)',
        group: 'notification',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 50,
      },

      employee_lead_notifications_emails: {
        name: 'employee_lead_notifications_emails',
        value: '',
        maxEmail: 20,
        type: 'multiEmail',
        id: 'multi-email-id-3',
        label:
          'Send Employee Lead Notification To: (Max 20 emails with comma separated values)',
        group: 'notification',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
      },
      employee_lead_notifications_phones: {
        name: 'employee_lead_notifications_phones',
        value: '',
        maxMobileNumbers: 10,
        type: 'multiPhone',
        id: 'multi-phone-id-3',
        label:
          'Send Employee Lead Notification SMS/Text To: (Max 10 numbers with comma separated values)',
        group: 'notification',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 50,
      },
      franchisee_lead_notifications_emails: {
        name: 'franchisee_lead_notifications_emails',
        value: '',
        maxEmail: 20,
        type: 'multiEmail',
        id: 'multi-email-id-4',
        label:
          'Send Franchisee Lead Notification To: Max 20 emails with comma separated values)',
        group: 'notification',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 50,
      },
      franchisee_lead_notifications_phones: {
        name: 'franchisee_lead_notifications_phones',
        value: '',
        maxMobileNumbers: 10,
        type: 'multiPhone',
        id: 'multi-phone-id-4',
        label:
          'Send Franchisee Lead Notification SMS/Text To: (Max 10 numbers with comma separated values)',
        group: 'notification',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 50,
      },
      UUID: {
        name: 'UUID',
        value: '',
        type: 'text',
        id: 'agency-UUID-id',
        label: 'Agency UUID',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 25,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
        required: true,
        showToUserType: ['BOOMERS_ADMIN'],
      },
      search_url: {
        name: 'search_url',
        value: '',
        type: 'text',
        id: 'search_url-id',
        label: 'Search URL',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 25,
        regExpValue:
          '^((https?|ftp|smtp):\\/\\/)?(www.)?[a-z0-9]+\\.[a-z]+(\\/[a-zA-Z0-9#]+\\/?)*$',
        showToUserType: ['BOOMERS_ADMIN'],
      },
      admin_notes: {
        name: 'admin_notes',
        value: '',
        type: 'text',
        id: 'admin_notes',
        label: 'Admin Notes',
        margin: 'normal',
        fullWidth: true,
        multiline: true,
        rows: 1,
        placeholder: '',
        required: false,
        error: false,
        width: 50,
        regExpValue: /^.{0,200}$/,
        showToUserType: ['BOOMERS_ADMIN'],
      },
    },
  },
];

export default FormFields;
