import React from 'react';
import { Typography } from '@material-ui/core';

const FormFields = [
  {
    id: 'agency-basic-info-form-fields',
    title: '',
    type: 'AGENCY_BASIC_INFO',
    list: [
      {
        name: 'name',
        value: '',
        type: 'text',
        id: 'agency-name-id',
        label: 'Organization Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'agency_website_url',
        value: '',
        type: 'text',
        id: 'agency-website-id',
        label: 'Website',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
      },
      {
        name: 'country',
        value: '',
        type: 'select',
        id: 'agency-country-id',
        label: 'Country',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 15,
        values: [
          {
            label: 'USA',
            value: 'USA',
          },
          {
            label: 'Canada',
            value: 'CANADA',
          },
        ],
      },
      {
        name: 'address',
        value: '',
        type: 'text',
        id: 'agency-address-id',
        label: 'Address',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 25,
        regExpValue: '\\b[a-zA-Z0-9]{1,50}\\b',
      },
      {
        name: 'city',
        value: '',
        type: 'text',
        id: 'agency-city-id',
        label: 'City',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 20,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'state',
        value: '',
        type: 'select',
        id: 'agency-state-id',
        label: 'State',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 25,
        values: [],
      },
      {
        name: 'zip_postal_code',
        value: '',
        type: 'text',
        id: 'agency-zip-id',
        label: 'Zip Code',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 15,
        regExpValue: '\\b[0-9]{4,8}\\b',
      },
      {
        name: 'agency_type',
        value: [],
        type: 'select',
        id: 'agency-type-id',
        label: 'Type',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 25,
        values: [],
      },
      {
        name: 'service_provided',
        value: '',
        type: 'multiSelect',
        id: 'service-provided-id',
        label: 'Service Provided',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 25,
        values: [],
      },
      {
        name: 'description',
        value: '',
        type: 'text',
        id: 'agency-description-id',
        label: 'Description (Optional - 500 words max)',
        hint: '500 Char Max',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 50,
        regExpValue: '^$|^.{1,500}$',
      },
      {
        name: 'min_hours_per_visit',
        value: '',
        type: 'number',
        id: 'min_hours_per_visit',
        label: 'Minimum Hours of Care Per visit',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 30,
        haveCommonField: true,
        commonFieldHeading: 'Minimum Hours of Care Required',
        hidden: false,
      },
      {
        name: 'min_hours_per_day',
        value: '',
        type: 'number',
        id: 'min_hours_per_day',
        label: 'Minimum Hours of Care Per day',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 30,
        haveCommonField: true,
        boxProps: {
          paddingTop: 3,
        },
        hidden: false,
      },
      {
        name: 'min_hours_per_week',
        value: '',
        type: 'number',
        id: 'min_hours_per_week',
        label: 'Minimum Hours of Care Per week',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 30,
        haveCommonField: true,
        boxProps: {
          paddingTop: 3,
        },
        hidden: false,
      },
      {
        name: 'pricing_hour_detail',
        value: '',
        type: 'text',
        id: 'pricing_hour_detail',
        label:
          'Pricing and Hours Details and Terms (Enter any details about minimum hours/days and any rate information you want to share with visitors.)',
        hint:
          'Enter any details about minimum hours/days and any rate information you want to share with visitors.',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 66,
        regExpValue: '^$|^.{1,500}$',
        boxProps: {
          paddingTop: 3,
        },
      },
      {
        name: 'payment_option',
        value: [],
        type: 'multiSelect',
        id: 'payment_option',
        label: 'Payment Options',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 30,
        values: [],
      },
    ],
  },
  {
    id: 'agency-office-info-form-fields',
    title: '',
    type: 'AGENCY_OFFICE_INFO',
    list: [
      {
        name: 'office_hours_timezone',
        value: '',
        type: 'select',
        id: 'time-zone-id',
        label: 'Time Zone',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 25,
        values: [],
      },
      {
        name: 'office_hours_from',
        value: '09:00',
        type: 'time',
        id: 'from-time-id',
        label: 'Hours From',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 30,
      },
      {
        name: 'office_hours_to',
        value: '17:00',
        type: 'time',
        id: 'to-time-id',
        label: 'Hours To',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 30,
      },
      {
        name: '24hour',
        type: 'singleCheckbox',
        id: '24hour-id',
        label: 'Open 24 Hours',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 15,
        value: false,
        labelPlacement: 'end',
      },
      {
        name: 'coverage_area',
        value: '',
        type: 'text',
        id: 'coverage-area-id',
        label: (
          <>
            <Typography variant="body1" component="span">
              Coverage Area Cities &nbsp;&nbsp;
              <Typography variant="caption">(comma separated)</Typography>
            </Typography>
          </>
        ),
        margin: 'normal',
        fullWidth: true,
        multiline: true,
        rows: 3,
        placeholder: '',
        error: false,
        required: true,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,50}\\b',
      },
      {
        name: 'coverage_area_cities',
        value: '',
        type: 'text',
        id: 'coverage-cities-id',
        label: (
          <>
            <Typography variant="body1" component="span">
              Coverage Zip codes &nbsp;&nbsp;
              <Typography variant="caption">(comma separated)</Typography>
            </Typography>
          </>
        ),
        hint: '(hint:- comma separated)',
        margin: 'normal',
        fullWidth: true,
        multiline: true,
        rows: 3,
        placeholder: '',
        error: false,
        required: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,50}\\b',
      },
    ],
  },
  {
    id: 'agency-notification-email-phone',
    title: '',
    type: 'NOTIFICATION',
    list: [
      {
        id: 'register-agency-notification-email-phone1',
        defaultExpanded: true,
        title: 'Send Client Lead Notifications to:',
        info:
          'Enter the email addresses and cell phone numbers of individuals who wish to receive notifications for Client Leads. Cell numbers will receive text messages. Text messaging fees from your wireless carrier may apply.',
        type: 'notification',
        accordionState: 'clientLeadNotification',
        style: { width: '100%' },
        list: [
          {
            name: 'client_lead_notifications_emails',
            value: '',
            maxEmail: 20,
            type: 'multiEmail',
            id: 'multi-email-id-1',
            label: 'Email Address (Max 20 with comma separated values)',
            group: 'notification',
            margin: 'normal',
            fullWidth: true,
            multiline: false,
            placeholder: '',
            required: true,
            error: false,
            width: 100,
          },
          {
            name: 'client_lead_notification_phones',
            value: '',
            maxMobileNumbers: 10,
            type: 'multiPhone',
            id: 'multi-phone-id-1',
            label:
              'SMS/Text Phone Numbers (Max 10 with comma separated values)',
            group: 'notification',
            margin: 'normal',
            fullWidth: true,
            multiline: false,
            placeholder: '',
            required: false,
            error: false,
            width: 100,
          },
        ],
      },
      {
        id: 'register-agency-notification-email-phone2',
        defaultExpanded: true,
        title: 'Send Caregiver/Job Lead Notifications to:',
        info:
          'Enter the email addresses and cell phone numbers of individuals who wish to receive notifications for Caregiver/Job Leads. Cell numbers will receive text messages. Text messaging fees from your wireless carrier may apply.',
        type: 'notification',
        accordionState: 'employeeLeadNotification',
        style: { width: '100%' },
        list: [
          {
            name: 'employee_lead_notifications_emails',
            value: '',
            maxEmail: 20,
            type: 'multiEmail',
            id: 'multi-email-id-2',
            label: 'Email Address (Max 20 with comma separated values)',
            group: 'notification',
            margin: 'normal',
            fullWidth: true,
            multiline: false,
            placeholder: '',
            required: true,
            error: false,
            width: 100,
          },
          {
            name: 'employee_lead_notifications_phones',
            value: '',
            maxMobileNumbers: 10,
            type: 'multiPhone',
            id: 'multi-phone-id-2',
            label:
              'SMS/Text Phone Numbers (Max 10 with comma separated values)',
            group: 'notification',
            margin: 'normal',
            fullWidth: true,
            multiline: false,
            placeholder: '',
            required: false,
            error: false,
            width: 100,
          },
        ],
      },
    ],
  },
  {
    id: 'payment-form-fields',
    title: '',
    type: 'PAYMENT',
    style: {
      justifyContent: 'center',
      alignContent: 'center',
    },
    list: [
      {
        name: 'billing_email',
        value: '',
        type: 'email',
        id: 'billing-email-id',
        label: 'Billing Notification Email Address',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 50,
        regExpValue: '\\S+@\\S+\\.\\S+',
      },
    ],
  },
];

export default FormFields;
