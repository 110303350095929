import React from 'react';
import { Typography } from '@material-ui/core';

const FormFields = [
  {
    id: 'register-admin-page',
    title: '',
    type: 'REGISTER_ADMIN_PAGE',
    list: [
      {
        name: 'firstName',
        value: '',
        type: 'text',
        id: 'first-name',
        label: 'First Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'lastName',
        value: '',
        type: 'text',
        id: 'last-name',
        label: 'Last Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'email',
        value: '',
        type: 'email',
        id: 'email-id',
        label: 'Admin Email Address (Login Id)',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue:
          '^\\w+([\\.\\-]?\\w+)*@\\w+([\\.\\-]?\\w+)*(\\.\\w{2,4})+$',
      },
      {
        name: 'cellPhone',
        value: '',
        type: 'phone',
        id: 'cellPhone-id',
        label: 'Phone Number',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '(XXX) XXX-XXXX',
        required: true,
        error: false,
        width: 50,
        regExpValue: '^\\([2-9]\\d\\d\\) [2-9]\\d\\d-\\d{4}$',
      },
    ],
  },
  {
    id: 'register-agency-basic-info-page',
    title: '',
    type: 'REGISTER_AGENCY_BASIC_INFO_PAGE',
    list: [
      {
        name: 'agencyName',
        value: '',
        type: 'text',
        id: 'agency-name-id',
        label: 'Organization Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'agencyWebsite',
        value: '',
        type: 'text',
        id: 'agency-website-id',
        label: 'Website',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
      },
      {
        name: 'agencyCountry',
        value: '',
        type: 'select',
        id: 'agency-country-id',
        label: 'Country',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 15,
        values: [
          {
            label: 'USA',
            value: 'USA',
          },
          {
            label: 'Canada',
            value: 'CANADA',
          },
        ],
      },
      {
        name: 'agencyAddress',
        value: '',
        type: 'text',
        id: 'agency-address-id',
        label: 'Address',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 25,
        regExpValue: '\\b[a-zA-Z0-9]{1,50}\\b',
      },
      {
        name: 'agencyCity',
        value: '',
        type: 'text',
        id: 'agency-city-id',
        label: 'City',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 20,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'agencyState',
        value: '',
        type: 'select',
        id: 'agency-state-id',
        label: 'State',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 25,
        values: [],
      },
      {
        name: 'agencyZip',
        value: '',
        type: 'text',
        id: 'agency-zip-id',
        label: 'Zip Code',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 15,
        regExpValue: '\\b[0-9]{4,8}\\b',
      },
      {
        name: 'agencyType',
        value: [],
        type: 'select',
        id: 'agency-type-id',
        label: 'Type',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 25,
        values: [],
      },
      {
        name: 'serviceProvided',
        value: '',
        type: 'multiSelect',
        id: 'service-provided-id',
        label: 'Service Provided',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 25,
        values: [],
      },
      {
        name: 'agencyDescription',
        value: '',
        type: 'text',
        id: 'agency-description-id',
        label: 'Description (Optional - 500 words max)',
        hint: '500 Char Max',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 50,
        regExpValue: '^$|^.{1,500}$',
      },
      {
        name: 'min_hours_per_visit',
        value: '',
        type: 'number',
        id: 'min_hours_per_visit',
        label: 'Minimum Hours of Care Per visit',
        watchKeyDown: true,
        minNumValue: 1,
        maxNumValue: 99,
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 30,
        haveCommonField: true,
        commonFieldHeading: 'Minimum Hours of Care Required',
        hidden: false,
        regExpValue: '^\\d{1,2}$',
      },
      {
        name: 'min_hours_per_day',
        value: '',
        type: 'number',
        id: 'min_hours_per_day',
        label: 'Minimum Hours of Care Per day',
        watchKeyDown: true,
        minNumValue: 1,
        maxNumValue: 99,
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 30,
        haveCommonField: true,
        boxProps: {
          paddingTop: 3,
        },
        hidden: false,
        regExpValue: '^\\d{1,2}$',
      },
      {
        name: 'min_hours_per_week',
        value: '',
        type: 'number',
        id: 'min_hours_per_week',
        label: 'Minimum Hours of Care Per week',
        watchKeyDown: true,
        minNumValue: 1,
        maxNumValue: 99,
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 30,
        haveCommonField: true,
        boxProps: {
          paddingTop: 3,
        },
        hidden: false,
        regExpValue: '^\\d{1,2}$',
      },
      {
        name: 'pricing_hour_detail',
        value: '',
        type: 'text',
        id: 'pricing_hour_detail',
        label:
          'Pricing and Hours Details and Terms (Enter any details about minimum hours/days and any rate information you want to share with visitors.)',
        hint:
          'Enter any details about minimum hours/days and any rate information you want to share with visitors.',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 66,
        regExpValue: '^$|^.{1,500}$',
      },
      {
        name: 'payment_option',
        value: [],
        type: 'multiSelect',
        id: 'payment_option',
        label: 'Payment Options',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 30,
        values: [],
        boxProps: {
          paddingTop: 3,
        },
      },
    ],
  },
  {
    id: 'register-agency-office-info-page',
    title: '',
    type: 'REGISTER_AGENCY_OFFICE_INFO_PAGE',
    list: [
      {
        name: 'timeZone',
        value: '',
        type: 'select',
        id: 'time-zone-id',
        label: 'Time Zone',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 25,
        values: [],
      },
      {
        name: 'fromTime',
        value: '09:00',
        type: 'time',
        id: 'from-time-id',
        label: 'Hours From',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 30,
      },
      {
        name: 'toTime',
        value: '17:00',
        type: 'time',
        id: 'to-time-id',
        label: 'Hours To',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 30,
      },
      {
        name: '24hour',
        type: 'singleCheckbox',
        id: '24hour-id',
        label: 'Open 24 Hours',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 15,
        value: false,
        labelPlacement: 'end',
      },
      {
        name: 'coverageArea',
        value: '',
        type: 'text',
        id: 'coverage-area-id',
        label: (
          <>
            <Typography variant="body1" component="span">
              Coverage Area Cities &nbsp;&nbsp;
              <Typography variant="caption">(comma separated)</Typography>
            </Typography>
          </>
        ),
        margin: 'normal',
        fullWidth: true,
        multiline: true,
        rows: 3,
        placeholder: '',
        error: false,
        required: true,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,50}\\b',
      },
      {
        name: 'coverageCities',
        value: '',
        type: 'text',
        id: 'coverage-cities-id',
        label: (
          <>
            <Typography variant="body1" component="span">
              Coverage Zip codes &nbsp;&nbsp;
              <Typography variant="caption">(comma separated)</Typography>
            </Typography>
          </>
        ),
        hint: '(hint:- comma separated)',
        margin: 'normal',
        fullWidth: true,
        multiline: true,
        rows: 3,
        placeholder: '',
        error: false,
        required: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,50}\\b',
      },
    ],
  },
  {
    id: 'register-agency-notification-email-phone',
    title: '',
    type: 'NOTIFICATION',
    list: [
      {
        id: 'register-agency-notification-email-phone1',
        defaultExpanded: true,
        title: 'Send Client Lead Notifications to:',
        info:
          'Enter the email addresses and cell phone numbers of individuals who wish to receive notifications for Client Leads. Cell numbers will receive text messages. Text messaging fees from your wireless carrier may apply.',
        type: 'notification',
        accordionState: 'clientLeadNotification',
        style: { width: '100%' },
        list: [
          {
            name: 'client_lead_notifications_emails',
            value: '',
            maxEmail: 20,
            type: 'multiEmail',
            id: 'multi-email-id-1',
            label: 'Email Address (Max 20 with comma separated values)',
            group: 'notification',
            margin: 'normal',
            changeFloatLabelColor: true,
            fullWidth: true,
            multiline: false,
            placeholder: '',
            required: true,
            error: false,
            width: 100,
          },
          {
            name: 'client_lead_notification_phones',
            value: '',
            maxMobileNumbers: 10,
            type: 'multiPhone',
            id: 'multi-phone-id-1',
            label:
              'SMS/Text Phone Numbers (Max 10 with comma separated values)',
            group: 'notification',
            margin: 'normal',
            changeFloatLabelColor: true,
            fullWidth: true,
            multiline: false,
            placeholder: '',
            required: false,
            error: false,
            width: 100,
          },
        ],
      },
      {
        id: 'register-agency-notification-email-phone2',
        defaultExpanded: true,
        title: 'Send Caregiver/Job Lead Notifications to:',
        info:
          'Enter the email addresses and cell phone numbers of individuals who wish to receive notifications for Caregiver/Job Leads. Cell numbers will receive text messages. Text messaging fees from your wireless carrier may apply.',
        type: 'notification',
        accordionState: 'employeeLeadNotification',
        style: { width: '100%' },
        list: [
          {
            name: 'employee_lead_notifications_emails',
            value: '',
            maxEmail: 20,
            type: 'multiEmail',
            id: 'multi-email-id-2',
            label: 'Email Address (Max 20 with comma separated values)',
            group: 'notification',
            margin: 'normal',
            changeFloatLabelColor: true,
            fullWidth: true,
            multiline: false,
            placeholder: '',
            required: true,
            error: false,
            width: 100,
          },
          {
            name: 'employee_lead_notifications_phones',
            value: '',
            maxMobileNumbers: 10,
            type: 'multiPhone',
            id: 'multi-phone-id-2',
            label:
              'SMS/Text Phone Numbers (Max 10 with comma separated values)',
            group: 'notification',
            margin: 'normal',
            changeFloatLabelColor: true,
            fullWidth: true,
            multiline: false,
            placeholder: '',
            required: false,
            error: false,
            width: 100,
          },
        ],
      },
    ],
  },
  {
    id: 'preferences-page',
    title: '',
    type: 'PREFERENCES',
    style: {
      justifyContent: 'center',
      alignContent: 'center',
    },
    list: [],
  },
  {
    id: 'payment-page',
    title: '',
    type: 'PAYMENT',
    style: {
      justifyContent: 'center',
      alignContent: 'center',
    },
    list: [
      {
        name: 'billing_email',
        value: '',
        type: 'email',
        id: 'billing-email-id',
        label: 'Billing Notification Email Address',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue:
          '^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})(\\s*,\\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})*$',
      },
    ],
  },
];

export default FormFields;
